class ZIDXWixDashboard implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxWixDashboardContainer";
    }
    getMatchURL(){
        return "/wix-dashboard";
    }
    render(){
        // console.log("wix dashboard");

    }
}